import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as miCasaStyles from "../styles/micasa.module.css"
import * as dinoStyles from "../styles/dinostore.module.css"
import Header from "../components/header"
import Footer from "../components/footer"
import Seo from "../components/seo"

import dinoHero from "../assets/dinostore/dinoHeroLg.png"
import dinoHeroMobile from "../assets/dinostore/dinoCircle.png"
import redDino from "../assets/dinostore/redDino.png"

import visionVideo from "../assets/dinostore/vision.mp4"
import collisionsVideo from "../assets/dinostore/collisions.mp4"
import scienceVideo from "../assets/dinostore/science.mp4"
import endingsVideo from "../assets/dinostore/endings.mp4"

import arrowDown from "../assets/dinostore/arrowDown.svg"

import model1 from "../assets/dinostore/model1.png"
import model2 from "../assets/dinostore/model2.png"
import model3 from "../assets/dinostore/model3.png"
import model4 from "../assets/dinostore/model4.png"

import dialogueVideo from "../assets/dinostore/Dialogue.mp4"


const Dinostore = () => (

    <main>
        <div id={miCasaStyles.topPageContainer}>
            <Seo title="Dino-Store"/>
            <Header home={false}/>
            <section id={miCasaStyles.heroContainer}>
                <div id={miCasaStyles.heroText}>
                    Dino-Store
                    <div id={miCasaStyles.heroSubtext}>
                        A stealth action game that teaches players how to be safe in public during COVID-19, and reflects on how the pandemic has changed our everyday lives.
                    </div>
                </div>
                <div class={dinoStyles.heroSvg}>
                    <img src={dinoHero} alt="Example of the vision cone mechanic from Dinostore"/>
                </div> 
                <div id={dinoStyles.mobileHero}>
                    <div id={dinoStyles.mobileHeroWrapper}>
                        <img src={dinoHeroMobile} alt="Example of the vision cone mechanic from Dinostore"/>
                    </div>
                    <div id={dinoStyles.triangleOutter}>
                        <div class={dinoStyles.triangleWrapper}>
                            <div id={dinoStyles.heroTriangle}/>
                        </div>
                    </div>
                    <div id={dinoStyles.redDino}>
                        <img src={redDino} alt="Red dinosaur"/>
                    </div>
                </div>
                <div id={miCasaStyles.heroSubtextMobile}>
                    A stealth action game that teaches players how to be safe in public during COVID-19, and reflects on how the pandemic has changed our everyday lives.
                </div>
            </section>
        </div>

        <section id={miCasaStyles.overviewOutterContainer}>
            <div id={miCasaStyles.overviewInnerContainer}>
                        <div class = {miCasaStyles.sectionHeader}>
                            Overview
                        </div>
                        <div id={miCasaStyles.ovSubheadersContainer}>
                            <div class={miCasaStyles.ovSubheaderItem}>
                                <div class={dinoStyles.ovSubheader}>
                                    Result
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    Unity game submitted<br/> to IndieCade 2021
                                </div>
                            </div>
                            <div class={miCasaStyles.ovSubheaderItem}>
                                <div class={dinoStyles.ovSubheader}>
                                    My role
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    Lead Unity developer
                                </div>
                            </div>
                            <div class={miCasaStyles.ovSubheaderItem}>
                                <div class={dinoStyles.ovSubheader}>
                                    The team
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    Drew Busch, Marian<br/> Dominguez, Terra Gasque,<br/> May Lawver, Yuchen Zhao
                                </div>
                            </div>
                            <div class={miCasaStyles.ovSubheaderItem} id={miCasaStyles.ovSubheaderItemNoMargin}>
                                <div class={dinoStyles.ovSubheader}>
                                    Tools
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    Unity, Figma
                                </div>
                            </div>
                        </div>

                        <div id={miCasaStyles.timelineContainer}>
                            <div class={dinoStyles.ovSubheader}>
                                        Timeline
                            </div>
                            <div class={miCasaStyles.ovSubheaderText}>
                                May 2020 - May 2021 
                            </div>
                            <StaticImage 
                                src="../assets/dinostore/timeline.png"
                                alt="Timeline of project work"
                                className={miCasaStyles.timelineImg}
                                quality={100}
                            />
                        </div>
                        
                        <div class={miCasaStyles.sectionContainer}>
                            <div class={miCasaStyles.pageSubheader}>
                                Description
                            </div>
                            <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.solutionBody}>
                                A few months into the pandemic, I was hired by the <a href="https://dilac.iac.gatech.edu/" target="_blank" rel="noreferrer">Digital Interactive Liberal Arts Center</a> (DILAC) at Georgia Tech to help create a game about COVID-19. Initially, <span class={dinoStyles.yellowText}>the game had two primary goals: to reflect on the difficulties and anxieties of our new shared reality under COVID, as well as to help people understand how COVID can spread in public spaces.</span>
                                <br/><br/>
                                The result was Dino-Store: Shopping in a Pandemic, a 2D stealth action game where the player must navigate increasingly risky grocery stores to successfully purchase the items they need for their child’s birthday party. The player must balance time against safety – moving recklessly increases the odds of bumping into other shoppers, which increases the chances of catching COVID.
                            </div>
                        </div>

                        <div class={dinoStyles.gifBlock} id={dinoStyles.firstGif}>
                            <div class={dinoStyles.gifText}>
                                A limited field of vision
                                <div class={dinoStyles.gifBody}>
                                    Just like the real world, in Dino-Store it’s impossible to know where every NPC and item in the store is. The player must use a limited vision cone to hunt down target items and track the location of other dinosaurs.
                                </div>
                            </div>
                            <div class={dinoStyles.overviewGif}>
                                <video autoPlay loop muted playsInline src={visionVideo}/>
                            </div>
                        </div>

                        <div class={dinoStyles.gifBlock}>
                            <div class={`${dinoStyles.overviewGif} ${miCasaStyles.orderTwo}`}>
                                <video autoPlay loop muted playsInline src={collisionsVideo}/>
                            </div>
                            <div class={dinoStyles.gifText}>
                                Avoid other customers
                                <div class={dinoStyles.gifBody}>
                                    It’s the middle of a pandemic, but other dinosaurs have to grocery shop too. With a limited field of vision, can you maintain social distance while other dinos are dashing through the store? Colliding with dinos who are sick increases your risk of infection!
                                </div>
                            </div>
                        </div>

                        <div class={dinoStyles.gifBlock}>
                            <div class={dinoStyles.gifText}>
                                    Based on science
                                <div class={dinoStyles.gifBody}>
                                    Dino-Store’s COVID modeling system was created in partnership with a COVID researcher and epidemiologist at the Georgia Institute of Technology. The C# COVID model <a href="https://www.twitch.tv/videos/699454158" target="_blank" rel="noreferrer">was presented at IndieCade’s Jamming the Curve event</a>, and exported as a Unity package for other developers to use in their games.
                                </div>
                            </div>
                            <div class={dinoStyles.overviewGif}>
                                <video autoPlay loop muted playsInline src={scienceVideo}/>
                            </div>
                        </div>

                        <div class={dinoStyles.gifBlock}>
                            <div class={`${dinoStyles.overviewGif} ${miCasaStyles.orderTwo}`}>
                                <video autoPlay loop muted playsInline src={endingsVideo}/>
                            </div>
                            <div class={dinoStyles.gifText}>
                                Multiple endings
                                <div class={dinoStyles.gifBody}>
                                    Did you buy everything you needed? Did you get sick? Depending on one’s performance in the game, the player gets varying endings (from successful birthdays, to missing the party entirely).
                                </div>
                            </div>
                        </div>

                        <div class={miCasaStyles.sectionContainer}>
                            <div class={miCasaStyles.pageSubheader}>
                                Playable build
                            </div>
                            <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.linkBody}>
                                Dino-Store can be downloaded and played from itch.io <a href="https://rwinstead.itch.io/dino-store-shopping" target="_blank" rel="noreferrer">at this link</a>. If you are interested in the source code for the game, feel free to reach out!
                            </div>
                        </div>

                        <div class={miCasaStyles.sectionContainer} id={miCasaStyles.contributionContainer}>
                            <div class={miCasaStyles.pageSubheader}>
                                My contribution
                            </div>
                            <div id={miCasaStyles.contributionContent}>
                                <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.contributionBody}>
                                    <span class={dinoStyles.yellowText}>I served as the lead Unity developer on Dino-Store,</span> responsible for creating and presenting the game’s COVID model backend, overseeing the team’s other developers, implementing core gameplay mechanics, and code optimization. I also designed and implemented the game’s UI, doing the design work in Figma and importing it into Unity.
                                </div>
                                <StaticImage 
                                        src="../assets/dinostore/eggbert.png"
                                        alt="Eggbert, a child dinosaur from Dinostore"
                                        class={dinoStyles.eggbert}
                                        placeholder={"blurred"}
                                        quality={100}
                                />
                            </div>
                        </div>

            </div> {/* End of overview content div */}
        </section>

        <section id={miCasaStyles.outterDetailsContainer}>
            <div id={miCasaStyles.innerDetailsContainer}>
                <div class = {miCasaStyles.sectionHeader}>
                    Details
                </div>
                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                        How it started
                </div>
                <div class={miCasaStyles.bodyText}>
                    Dino-Store was born out of <a href="https://www.indiecade.com/jammingthecurve/" target="_blank" rel="noreferrer">IndieCade’s 2020 Jamming the Curve: COVID-19 game jam</a> that took place over two weeks in September 2020. The goal of the jam was to leverage the power of games to help promote prosocial behaviors based on science and critical thinking that help flatten the COVID-19 curve.
                </div>

                <div class={dinoStyles.indentedWrapper}>
                    <StaticImage 
                        src="../assets/dinostore/jammingthecurve.png"
                        alt="Jamming the curve September 2020 poster"
                        class={dinoStyles.jammingTheCurve}
                        placeholder={"blurred"}
                        quality={100}
                        width={598}
                    />
                </div>

                <div class={miCasaStyles.bodyText} style={{marginTop: "3.5rem"}}>
                    While a lot of the ideation and COVID modeling was done in advance of the jam, nearly all of the core game mechanics were built entirely within the game jam’s two week period. At the time, there were only two developers dedicated to Dino-Store, including myself.
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={miCasaStyles.indentedInner}>
                        <div class={`${dinoStyles.bar} ${dinoStyles.yellow1} `}/>
                        <div class={miCasaStyles.detailsHeader}>
                            Initial inspiration and design
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            A common question we got about Dino-Store is “why a grocery store?” After all, COVID has touched every facet of our lives, so the game could be about anything. However, <span class={dinoStyles.yellowText}>we were drawn from the beginning to supermarkets because, for many of us, it was the only regular interaction we had with the general public.</span> 
                            <br/><br/>
                            In terms of the feel of the game, we wanted Dino-Store to reflect the anxieties and uncertainies we were feeling while shopping ourselves. 
                        </div>
                    </div>
                </div>

                <div class={dinoStyles.indentedContentBelow}>
                    <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop5}`}>
                        Game design goals and implementation
                    </div>

                    <div class={dinoStyles.goalsContainer}>

                    <div class={dinoStyles.goalsItem}>
                            <div class={dinoStyles.goalsTop}>
                                <div class={dinoStyles.goalsNumber}>
                                    1
                                </div>
                                <div class={dinoStyles.goalsText}>
                                    Expose the player to real world policies that affect exposure risk in public (mask mandates, etc.)
                                </div>
                            </div>
                            <div>
                                <img src={arrowDown} alt="Downward arrow"/>
                            </div>
                            <div class={dinoStyles.goalsList}>
                                <div class={dinoStyles.goalsBulletItem}>
                                    <div class={dinoStyles.bulletWrapper}>
                                        <div class={dinoStyles.orangeBullet}/>
                                    </div>
                                    <div class={dinoStyles.bulletText}>
                                        Create multiple stores with different policies
                                    </div>
                                </div>
                                <div class={dinoStyles.goalsBulletItem}>
                                    <div class={dinoStyles.bulletWrapper}>
                                        <div class={dinoStyles.orangeBullet}/>
                                    </div>
                                    <div class={dinoStyles.bulletText}>
                                        Make differences in policies explicit; affect risk chance
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class={dinoStyles.goalsItem}>
                            <div class={dinoStyles.goalsTop}>
                                <div class={dinoStyles.goalsNumber}>
                                    2
                                </div>
                                <div class={dinoStyles.goalsText}>
                                    Reflect the anxiety and uncertainty of shopping during peak COVID
                                </div>
                            </div>
                            <div>
                                <img src={arrowDown} alt="Downward arrow"/>
                            </div>
                            <div class={dinoStyles.goalsList}>
                                <div class={dinoStyles.goalsBulletItem}>
                                    <div class={dinoStyles.bulletWrapper}>
                                        <div class={dinoStyles.orangeBullet}/>
                                    </div>
                                    <div class={dinoStyles.bulletText}>
                                        Distribute player attention for frantic affect
                                    </div>
                                </div>
                                <div class={dinoStyles.goalsBulletItem}>
                                    <div class={dinoStyles.bulletWrapper}>
                                        <div class={dinoStyles.orangeBullet}/>
                                    </div>
                                    <div class={dinoStyles.bulletText}>
                                        Constrain player information (fog of war)
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class={dinoStyles.goalsItem}>
                            <div class={dinoStyles.goalsTop}>
                                <div class={dinoStyles.goalsNumber}>
                                    3
                                </div>
                                <div class={dinoStyles.goalsText}>
                                    Help the player understand how COVID spreads in confined, public spaces
                                </div>
                            </div>
                            <div>
                                <img src={arrowDown} alt="Downward arrow"/>
                            </div>
                            <div class={dinoStyles.goalsList}>
                                <div class={dinoStyles.goalsBulletItem}>
                                    <div class={dinoStyles.bulletWrapper}>
                                        <div class={dinoStyles.orangeBullet}/>
                                    </div>
                                    <div class={dinoStyles.bulletText}>
                                        Make infection risk visible in UI
                                    </div>
                                </div>
                                <div class={dinoStyles.goalsBulletItem}>
                                    <div class={dinoStyles.bulletWrapper}>
                                        <div class={dinoStyles.orangeBullet}/>
                                    </div>
                                    <div class={dinoStyles.bulletText}>
                                        Make social distancing a core mechanic
                                    </div>
                                </div>
                                <div class={dinoStyles.goalsBulletItem}>
                                    <div class={dinoStyles.bulletWrapper}>
                                        <div class={dinoStyles.orangeBullet}/>
                                    </div>
                                    <div class={dinoStyles.bulletText}>
                                        Increase infection chance with time spent in space
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop4}`}>
                        Game jam result
                    </div>

                </div>

                    <div class={dinoStyles.twitchEmbed}>
                        <div class={dinoStyles.CAEmbed}>
                            <iframe className={dinoStyles.YTiframe} title="Twitch stream of Sean Bloom playing Dinostore" src="https://player.twitch.tv/?video=699460685&time=1h14m5s&parent=ryanwinstead.com&autoplay=false" frameBorder="0" allowFullScreen={true} scrolling="no" height="378" width="620"/>
                        </div>
                    </div>

                    <div class={dinoStyles.twitchEmbedMobile}>
                        <a href="https://www.twitch.tv/videos/699460685?t=01h14m05s" target="_blank" rel="noreferrer">
                            <StaticImage 
                                src="../assets/dinostore/twitch1.png"
                                alt="Twitch stream of Sean Bloom playing Dinostore"
                                class={dinoStyles.twitchImg}
                                placeholder={"blurred"}
                                quality={100}
                            />  
                        </a>
                    </div>

                <div class={`${miCasaStyles.bodyText} ${dinoStyles.indentedText} ${miCasaStyles.marginTop3} `} >
                    After two long weeks, a game jam prototype of Dino-Store was born! Sean Bloom, a game designer at USC and personal hero of mine, played Dino-Store at the game jam results event. Check out his hilarious play through above.
                </div>

                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                    Creating a COVID model in Unity
                </div>
                <div class={miCasaStyles.bodyText}>
                    On its surface, Dino-Store seems like a lighthearted game about avoiding catching COVID (and it is); however, there is some serious science operating underneath the hood.
                    <br/><br/>
                    <span class={dinoStyles.yellowText}>A key part of Jamming of the Curve was a partnership between IndieCade and DILAC that included the creation of a COVID model that other developers could use in their games.</span> To achieve this, I worked with Marian Dominguez, a COVID researcher and epidemiologist at Georgia Tech. Through weekly meetings, we worked together to turn her research and knowledge into C# scripts that we could use in the game.
                </div>

                <div class={miCasaStyles.indentedContainer}>
                    <div class={miCasaStyles.indentedInner}>
                        <div class={`${dinoStyles.bar} ${dinoStyles.yellow2} `}/>
                        <div class={miCasaStyles.detailsHeader}>
                            How it works
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            Ultimately the COVID infection risk in Dino-Store is dramatized (you aren’t likely to faceplant into dozens of other people in a store, and the game was developed when a vaccine did not exist). However, infection risk probabilities are backed by scientific research.
                        </div>
                    </div>
                </div>

                <div class={dinoStyles.indentedContentBelow}>
                    <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop4}`}>
                        What the COVID model handles
                    </div>

                    <div class={dinoStyles.modelContainer}>
                        <div class={dinoStyles.modelPair}>
                            <img src={model1} class={dinoStyles.modelImg} alt="Three people, one is infected"/>
                            <img src={model2} class={dinoStyles.modelImg} alt="Clock"/>
                        </div>
                        <div class={dinoStyles.modelPair}>
                            <img src={model3} class={dinoStyles.modelImg} alt="Person wearing a mask"/>
                            <img src={model4} class={dinoStyles.modelImg} alt="Two people on a bench 6 feet apart"/>
                        </div>
                    </div>
                </div>


                <div class={`${miCasaStyles.bodyText} ${dinoStyles.indentedText} ${miCasaStyles.marginTop4}`} >
                    To allow other developers to use the model, I created a Unity package and documentation that encapuslated Dino-Store’s COVID system. I then presented it at IndieCade’s Jamming the Curve event, and made the code available at this <a href="https://github.com/GT-DILAC/unityengine" target="_blank" rel="noreferrer">Githib repository</a>. Check out the video below to learn more!
                </div>
                
                    <div class={dinoStyles.twitchEmbed}>
                        <div class={dinoStyles.CAEmbed} style={{marginTop: '2rem'}}>
                            <iframe className={dinoStyles.YTiframe} title="Me presenting the COVID model in Dinostore at an IndieCade event" src="https://player.twitch.tv/?video=699454158&parent=ryanwinstead.com&autoplay=false&time=2h31m0s" frameborder="0" allowFullScreen={true} scrolling="no" height="378" width="620"/>                    </div>
                    </div>

                    <div class={dinoStyles.twitchEmbedMobile}>
                        <a href="https://www.twitch.tv/videos/699454158?t=02h30m56s" target="_blank" rel="noreferrer">
                            <StaticImage 
                                src="../assets/dinostore/twitch2.png"
                                alt="Me presenting the COVID model in Dinostore at an IndieCade event"
                                class={dinoStyles.twitchImg}
                                placeholder={"blurred"}
                                quality={100}
                            />  
                        </a>
                    </div>

                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                    Fleshing out the game
                </div>
                <div class={miCasaStyles.bodyText}>
                    With the game jam done, we set out to turn Dino-Store into a complete game that met our initial ambitions. Over the next seven months, we were able to add the following features to the game.
                </div>

                <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop5}`}>
                    Features implemented
                </div>

                <div class={`${dinoStyles.featureTextContainer} ${miCasaStyles.marginTop5}`}>
                    <div class={dinoStyles.featureNumber}>
                        1
                    </div>
                    <div class={`${miCasaStyles.bodyTextSemiBold}`}>
                        Three playable stores with varying COVID policies
                    </div>
                </div>

                <div class={`${dinoStyles.featureFlex} ${miCasaStyles.marginTop1}`}>
                    <div class={dinoStyles.featureFlexImgContainer}>
                        <StaticImage 
                                src="../assets/dinostore/store1.png"
                                alt="Dinostore 1"
                                class={dinoStyles.featureFlexImg}
                                placeholder={"blurred"}
                                quality={100}
                        />    
                        <div class={`${miCasaStyles.imageCaption2} ${miCasaStyles.marginTop1} `}>
                            Corner store 
                        </div>                    
                    </div>
                    <div class={dinoStyles.featureFlexImgContainer}>
                        <StaticImage 
                                src="../assets/dinostore/store2.png"
                                alt="Dinostore 2"
                                class={dinoStyles.featureFlexImg}
                                placeholder={"blurred"}
                                quality={100}
                        />    
                        <div class={`${miCasaStyles.imageCaption2} ${miCasaStyles.marginTop1} `}>
                            Local grocery
                        </div>                    
                    </div>
                    <div class={dinoStyles.featureFlexImgContainer}>
                        <StaticImage 
                                src="../assets/dinostore/store3.png"
                                alt="Dinostore 3"
                                class={dinoStyles.featureFlexImg}
                                placeholder={"blurred"}
                                quality={100}
                        />    
                        <div class={`${miCasaStyles.imageCaption2} ${miCasaStyles.marginTop1} `}>
                            Supermarket 
                        </div>                    
                    </div>
                </div>

                <div class={`${dinoStyles.featureTextContainer} ${miCasaStyles.marginTop6}`}>
                    <div class={dinoStyles.featureNumber}>
                        2
                    </div>
                    <div class={`${miCasaStyles.bodyTextSemiBold}`}>
                        A new narrative and dialogue system
                    </div>
                </div>

                <div id={dinoStyles.dialogueWrapper}>
                    <div class={dinoStyles.dialogueVideo}>
                        <video autoPlay loop muted playsInline src={dialogueVideo}/>
                    </div>
                </div>

                <div class={`${dinoStyles.featureTextContainer} ${miCasaStyles.marginTop6}`}>
                    <div class={dinoStyles.featureNumber}>
                        3
                    </div>
                    <div class={`${miCasaStyles.bodyTextSemiBold}`}>
                        Brand new UI
                    </div>
                </div>

                <div class={`${dinoStyles.featureFlex} ${miCasaStyles.marginTop1}`}>
                    <div class={dinoStyles.featureFlexImgContainer}>
                        <StaticImage 
                                src="../assets/dinostore/UI1.png"
                                alt="Dinostore homescreen"
                                class={dinoStyles.featureFlexImg}
                                placeholder={"blurred"}
                                quality={100}
                        />    
                        <div class={`${miCasaStyles.imageCaption2} ${miCasaStyles.marginTop1} `}>
                            Main menu
                        </div>                    
                    </div>
                    <div class={dinoStyles.featureFlexImgContainer}>
                        <StaticImage 
                                src="../assets/dinostore/UI2.png"
                                alt="In-game shopping list UI"
                                class={dinoStyles.featureFlexImg}
                                placeholder={"blurred"}
                                quality={100}
                        />    
                        <div class={`${miCasaStyles.imageCaption2} ${miCasaStyles.marginTop1} `}>
                            Shopping list
                        </div>                    
                    </div>
                    <div class={dinoStyles.featureFlexImgContainer}>
                        <StaticImage 
                                src="../assets/dinostore/UI3.png"
                                alt="In-game menu"
                                class={dinoStyles.featureFlexImg}
                                placeholder={"blurred"}
                                quality={100}
                        />    
                        <div class={`${miCasaStyles.imageCaption2} ${miCasaStyles.marginTop1} `}>
                            In-game menu
                        </div>                    
                    </div>
                </div>

                <div class={`${miCasaStyles.flexBox} ${miCasaStyles.marginTop3}`}>
                    <StaticImage 
                            src="../assets/dinostore/HUD.png"
                            alt="In-game HUD"
                            class={dinoStyles.HUDImg}
                            placeholder={"blurred"}
                            quality={100}
                    /> 
                </div>
                <div class={`${miCasaStyles.imageCaption2} ${miCasaStyles.marginTop1} `}>
                    HUD
                </div>  

                <div class={`${dinoStyles.featureTextContainer} ${miCasaStyles.marginTop6}`}>
                    <div class={dinoStyles.featureNumber}>
                        4
                    </div>
                    <div class={`${miCasaStyles.bodyTextSemiBold}`}>
                        Original music (by May Lawver)
                    </div>
                </div>
                
                <div id={dinoStyles.soundCloud}>
                    <div class={dinoStyles.scEmbed}>
                        <iframe title="Main menu music" width="300" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1054198129%3Fsecret_token%3Ds-adE8Mav8bRY&color=%23fab311&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                        <div style={{fontSize: '10px', color: '#cccccc', lineBreak: 'anywhere', wordBreak: 'normal', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif', fontWeight: '100'}}><a href="https://soundcloud.com/ryan-winstead-114761427" title="Ryan Winstead" rel="noreferrer" target="_blank" style={{color: '#cccccc', textDecoration: 'none'}}>May Lawver · </a><a href="https://soundcloud.com/ryan-winstead-114761427/title-screen/s-adE8Mav8bRY" rel="noreferrer" title="Title Screen" target="_blank" style={{color: '#cccccc', textDecoration: 'none'}}>Title Screen</a></div>
                    </div>

                    <div class={dinoStyles.scEmbed}>
                        <iframe title="Ending bad music" width="300" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1054197943%3Fsecret_token%3Ds-FlFrD4gXV4j&color=%23fab311&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                        <div style={{fontSize: '10px', color: '#cccccc', lineBreak: 'anywhere', wordBreak: 'normal', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif', fontWeight: '100'}}><a href="https://soundcloud.com/ryan-winstead-114761427" title="Ryan Winstead" rel="noreferrer" target="_blank" style={{color: '#cccccc', textDecoration: 'none'}}>May Lawver · </a><a href="https://soundcloud.com/ryan-winstead-114761427/ending-bad" rel="noreferrer" title="Title Screen" target="_blank" style={{color: '#cccccc', textDecoration: 'none'}}>Ending (bad)</a></div>
                    </div>

                    <div class={dinoStyles.scEmbed}>
                        <iframe title="Ending good music" width="300" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1054197904%3Fsecret_token%3Ds-OXFL1O420Tg&color=%23fab311&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                        <div style={{fontSize: '10px', color: '#cccccc', lineBreak: 'anywhere', wordBreak: 'normal', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif', fontWeight: '100'}}><a href="https://soundcloud.com/ryan-winstead-114761427" title="Ryan Winstead" rel="noreferrer" target="_blank" style={{color: '#cccccc', textDecoration: 'none'}}>May Lawver · </a><a href="https://soundcloud.com/ryan-winstead-114761427/ending-good" rel="noreferrer" title="Title Screen" target="_blank" style={{color: '#cccccc', textDecoration: 'none'}}>Ending (good)</a></div>
                    </div>
                </div>

                <div class={`${dinoStyles.featureTextContainer} ${miCasaStyles.marginTop6}`}>
                    <div class={dinoStyles.featureNumber}>
                        5
                    </div>
                    <div class={`${miCasaStyles.bodyTextSemiBold}`}>
                        Unique endings
                    </div>
                </div>

                <div class={`${dinoStyles.featureFlex} ${miCasaStyles.marginTop1}`}>
                    <div class={dinoStyles.featureFlexImgContainer}>
                        <StaticImage 
                                src="../assets/dinostore/ending1.png"
                                alt="Best ending"
                                class={dinoStyles.featureFlexImg}
                                placeholder={"blurred"}
                                quality={100}
                        />    
                        <div class={`${miCasaStyles.imageCaption2} ${miCasaStyles.marginTop1} `}>
                            Best ending (healthy, right items)
                        </div>                    
                    </div>
                    <div class={dinoStyles.featureFlexImgContainer}>
                        <StaticImage 
                                src="../assets/dinostore/ending2.png"
                                alt="Worst ending"
                                class={dinoStyles.featureFlexImg}
                                placeholder={"blurred"}
                                quality={100}
                        />    
                        <div class={`${miCasaStyles.imageCaption2} ${miCasaStyles.marginTop1} `}>
                            Bad ending (sick, wrong items)
                        </div>                    
                    </div>
                    <div class={dinoStyles.featureFlexImgContainer}>
                        <StaticImage 
                                src="../assets/dinostore/ending3.png"
                                alt="Medium ending"
                                class={dinoStyles.featureFlexImg}
                                placeholder={"blurred"}
                                quality={100}
                        />    
                        <div class={`${miCasaStyles.imageCaption2} ${miCasaStyles.marginTop1} `}>
                            Good ending (healthy, wrong items)
                        </div>                    
                    </div>
                </div>

                <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                    Reflection
                </div>
                <div class={miCasaStyles.bodyText}>
                    I really enjoyed my time working on Dino-Store, and learned so much by working on a game with a longer development cycle and diverse team.
                    <br/><br/>
                    Below are a few of my key takeaways:
                </div>

                <div class={dinoStyles.reflectionItem}>
                   <div class={dinoStyles.orangeBullet} style={{marginRight: '1.5rem'}}/>
                   <div class={miCasaStyles.bodyText}>
                        <span class={dinoStyles.yellowText}>Clean project and code organization are crucial.</span> Over the course of a year, Dino-Store saw developers come and go as the semesters changed. As systems changed hands, a clear project structure, documentation, and clean code kept things from becoming spaghetti.
                   </div>
                </div>

                <div class={dinoStyles.reflectionItem}>
                   <div class={dinoStyles.orangeBullet} style={{marginRight: '1.5rem'}}/>
                   <div class={miCasaStyles.bodyText}>
                        <span class={dinoStyles.yellowText}>Visuals count twice.</span> One major regret I had for Dino-Store was that we didn’t have a dedicated artist on the team. While we were confident in the systems that we developed, a game is very heavily judged for its art style and visual feedback. Dino-Store suffered here.
                   </div>
                </div>

                <div class={dinoStyles.reflectionItem}>
                   <div class={dinoStyles.orangeBullet} style={{marginRight: '1.5rem'}}/>
                   <div class={miCasaStyles.bodyText}>
                        <span class={dinoStyles.yellowText}>Debugging and polish is the bulk of the work.</span> It’s easy to underestimate the amount of time it will take to integrate new features without bugs, or to make complex systems seamless in the game space. I will definitely allot more time for this in the future.
                   </div>
                </div>

                <div id={miCasaStyles.buttonsContainer} class={`${miCasaStyles.flexBox} ${miCasaStyles.marginTop7}`} style={{paddingBottom: "1rem"}}>
                    <Link to="/" class={miCasaStyles.linkButton}>
                        <div id={dinoStyles.takeMeHome} class={miCasaStyles.botButton}>
                            Take me home
                        </div>
                    </Link>
                    <Link to={`/dinostore#${miCasaStyles.topPageContainer}`}>
                        <div id={dinoStyles.backTop} class={miCasaStyles.botButton}>
                            Back to top
                        </div>
                    </Link>
                </div>

            </div> {/* End of details container */}
        </section>

        <Footer/>
    </main>
)


export default Dinostore



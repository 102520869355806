// extracted by mini-css-extract-plugin
export var CAEmbed = "dinostore-module--CAEmbed--hjAMV";
export var HUDImg = "dinostore-module--HUDImg--RvEDH";
export var YTiframe = "dinostore-module--YTiframe--jMJht";
export var backTop = "dinostore-module--backTop--pkGiU";
export var bar = "dinostore-module--bar--4XCFw";
export var bulletText = "dinostore-module--bulletText--kq3-v";
export var bulletWrapper = "dinostore-module--bulletWrapper--bFTPx";
export var dialogueVideo = "dinostore-module--dialogueVideo--D6pfg";
export var dialogueWrapper = "dinostore-module--dialogueWrapper--6tk1O";
export var eggbert = "dinostore-module--eggbert--WvhFl";
export var featureFlex = "dinostore-module--featureFlex--5kuWg";
export var featureFlexImg = "dinostore-module--featureFlexImg--1kmDw";
export var featureFlexImgContainer = "dinostore-module--featureFlexImgContainer--9Cx5m";
export var featureNumber = "dinostore-module--featureNumber--pT5Jp";
export var featureTextContainer = "dinostore-module--featureTextContainer--nOtka";
export var firstGif = "dinostore-module--firstGif--HOIQA";
export var gifBlock = "dinostore-module--gifBlock--BrhRT";
export var gifBody = "dinostore-module--gifBody--p1HaL";
export var gifText = "dinostore-module--gifText--HsFkM";
export var goalsBulletItem = "dinostore-module--goalsBulletItem--otzUH";
export var goalsContainer = "dinostore-module--goalsContainer--HAWNW";
export var goalsItem = "dinostore-module--goalsItem--RXtTk";
export var goalsList = "dinostore-module--goalsList--FCWix";
export var goalsNumber = "dinostore-module--goalsNumber---46hc";
export var goalsText = "dinostore-module--goalsText--l0J8Q";
export var goalsTop = "dinostore-module--goalsTop--46CDS";
export var heroImage = "dinostore-module--heroImage--Hi7HO";
export var heroSvg = "dinostore-module--heroSvg--IENIM";
export var heroTriangle = "dinostore-module--heroTriangle--6zr12";
export var indentedContentBelow = "dinostore-module--indentedContentBelow--KyzQ-";
export var indentedText = "dinostore-module--indentedText---RBrD";
export var indentedWrapper = "dinostore-module--indentedWrapper---VyFk";
export var jammingTheCurve = "dinostore-module--jammingTheCurve--wKocL";
export var mobileHero = "dinostore-module--mobileHero--+PdYA";
export var mobileHeroWrapper = "dinostore-module--mobileHeroWrapper--sYZei";
export var modelContainer = "dinostore-module--modelContainer--rRUgC";
export var modelImg = "dinostore-module--modelImg--Ckupr";
export var modelPair = "dinostore-module--modelPair--9pun1";
export var orangeBullet = "dinostore-module--orangeBullet--HgJaJ";
export var ovSubheader = "dinostore-module--ovSubheader--VqkzI";
export var overviewGif = "dinostore-module--overviewGif--zwf8L";
export var redDino = "dinostore-module--redDino--V5-ku";
export var reflectionItem = "dinostore-module--reflectionItem--eDDoK";
export var scEmbed = "dinostore-module--scEmbed--AVW8a";
export var soundCloud = "dinostore-module--soundCloud--QJOR8";
export var takeMeHome = "dinostore-module--takeMeHome--BoByQ";
export var triangleOutter = "dinostore-module--triangleOutter--B1F9u";
export var triangleWrapper = "dinostore-module--triangleWrapper--iWa43";
export var twitchEmbed = "dinostore-module--twitchEmbed--eWrTs";
export var twitchEmbedMobile = "dinostore-module--twitchEmbedMobile--eIOTm";
export var twitchImg = "dinostore-module--twitchImg--d+4mr";
export var yellow1 = "dinostore-module--yellow1--S4Cwa";
export var yellow2 = "dinostore-module--yellow2--VFhxM";
export var yellowText = "dinostore-module--yellowText--sHq7N";